import React, { useState, useEffect } from 'react';
import { Container } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import './index.scss';
import { StaticImage } from '../../utils/image';
import { Avatar, Skeleton } from 'antd';
import { encodeBase64 } from '../../utils/convertBase64';
import { ConvertDateShort } from '../../utils/date';
import { checkComplete, checkLeftTime, checkLeftTimeIcon, checkLeftTimeStatus } from '../../utils';
import ProgressBar from "../listeningV2/progressBar";
import TagDuoTone from '../iconComponent/TagDuoTone';
import ApiSurvey from '../../api/survey';
import Swal from 'sweetalert2'
import SurveyCardItem from './desktop/surveyCard';
import SurveyCardItemMobile from './mobile/surveyCard';
import { useWindowSize } from '../../utils/WindowSize';
import Tab1 from './Tab1';
import Tab4 from './Tab4';
import Tab2 from './Tab2';
import Tab6 from './Tab6';
import {
    FormOutlined,
    Loading3QuartersOutlined,
    FileOutlined,
    CloseCircleOutlined,
    ShareAltOutlined,
    ReloadOutlined,
} from '@ant-design/icons';
import { setLocalStorage } from '../../utils/localStorage';
import { checkUser, checkUserBool } from '../../utils/checkUser';
import { useSelector } from 'react-redux';
import Tab3 from './Tab3';
import Tab1Evaluate from './Tab1Evaluate';
import { useClipboard } from 'use-clipboard-copy';
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterIcon,
    TwitterShareButton,
    EmailShareButton,
    EmailIcon,
} from 'react-share';
import TabWebboard from './TabWebboard';
import OutsideClickHandler from 'react-outside-click-handler';
const SurveyDetail = (props) => {
    const userState = useSelector((state) => state.user);
    const [width, height] = useWindowSize();
    const history = useHistory();
    const survey_id = new URLSearchParams(props.location.search).get('survey_id');
    const key = new URLSearchParams(props.location.search).get('key');
    const [survey, setsurvey] = useState({});
    const [share, setshare] = useState({});
    const [showShare, setShowShare] = useState(false);
    const [law_group, setlaw_group] = useState([]);
    const [activeTab, setactiveTab] = useState(1);
    const [isContinue, setIsContinue] = useState(false);
    const [isSended, setIsSended] = useState(false);
    const [surveyStorage, setsurveyStorage] = useState("");
    const clipboard = useClipboard({ copiedTimeout: 800 });
    useEffect(() => {
        document.title = 'ข้อมูลการรับฟัง';
        getSurvey();
        if (checkUserBool(userState)) {
            checkAnswerSended();
            checkAnswer();
        }
        viewSurvey();
        if (key) {
            setinformerKey();
        }
    }, [])

    const getSurvey = async () => {
        Swal.showLoading();
        const survey = await ApiSurvey.getById({ survey_id });
        if (survey.status == 200) {
            Swal.close();
            const { data, law_group } = survey.data;

            if (!data?.survey_name) {
                Swal.fire('เกิดข้อผิดพลาด', 'ไม่พบข้อมูล Survey', 'warning').then(
                    (result) => {
                        history.go(-1);
                    }
                );
                return false;
            }
            setshare({
                title: `ขอเชิญชวนท่านร่วมแสดงความคิดเห็นต่อ ${data.survey_name
                    } ภายใต้โครงการ ${data.project_name} ได้ที่ ${window.location.origin
                    }/listeningDetail?survey_id=${survey_id
                    } ตั้งแต่วันที่ ${ConvertDateShort(
                        data.start_date,
                        'DD MMM YY'
                    )} ถึงวันที่ ${ConvertDateShort(data.end_date, 'DD MMM YY')}
    
            โดยเมื่อกระบวนการรับฟังความคิดเห็นได้ดำเนินการเสร็จสิ้นแล้ว หน่วยงานผู้รับผิดชอบจะได้เผยแพร่สรุปผลการรับฟังความคิดเห็นและรายงานการวิเคราะห์ผลกระทบของร่างกฎหมายหรือรายงานการประเมินผลสัมฤทธิ์ของกฎหมาย (แล้วแต่กรณี) ซึ่งท่านสามารถเข้าถึงได้ตาม Link ข้างต้น
    
            มาร่วมเป็นส่วนหนึ่งในการสร้างกฎหมายของประชาชน`,
                subject: `ขอเชิญชวนท่านร่วมแสดงความคิดเห็นต่อ ${data.survey_name
                    } ภายใต้โครงการ ${data.project_name} ได้ที่ ${window.location.origin
                    }/listeningDetail?survey_id=${survey_id
                    } ตั้งแต่วันที่ ${ConvertDateShort(
                        data.start_date,
                        'DD MMM YY'
                    )} ถึงวันที่ ${ConvertDateShort(data.end_date, 'DD MMM YY')}`,
                shareUrl: `${window.location.origin}/listeningDetail?survey_id=${survey_id}`,
                titleTW: `LAW : ${data?.agency_abbrv
                    } ได้เปิดรับฟังความคิดเห็น วันที่ ${ConvertDateShort(
                        data.start_date,
                        'DD/M/YY'
                    )} - ${ConvertDateShort(data.end_date, 'DD/M/YY')} สำหรับ ${data.project_name
                    }`,
                titleFB: `LAW : ${data?.agency_abbrv
                    } ได้เปิดรับฟังความคิดเห็น \nวันที่ ${ConvertDateShort(
                        data.start_date,
                        'DD/M/YY'
                    )} - ${ConvertDateShort(data.end_date, 'DD/M/YY')} \n
            สำหรับ ${data.project_name
                    } \n \nมาร่วมแสดงความคิดเห็น หรือติดตามความคืนหน้าโครงการ อื่นๆ ได้ที่เว็บไซต์ Law.go.th \n\n
            ${window.location.origin}/listeningDetail?survey_id=${survey_id}`,
            });
            console.log("survey::", data);
            setsurvey(data);
            setlaw_group(law_group);
        }
    };

    // useEffect(() => {
    //     if (survey.survey_type_id == 3) {
    //         setactiveTab(6);
    //     }
    // }, [survey])

    const deleteOldAnswer = () => {
        Swal.fire({
            title: 'คุณแน่ใจแล้วใช่ไหม?',
            text: 'ต้องการยกเลิกความคิดเห็นเดิม เพื่อเริ่มทำใหม่',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            reverseButtons: true,
        }).then(async (ok) => {
            if (ok.isConfirmed) {
                const result = await ApiSurvey.cancelAnswer({ id: survey.survey_id });
                if (result.status === 200) {
                    // history.push(`/DetailsList?survey_id=${encodeBase64(survey.survey_id)}`);
                    // window.location.reload();
                    setactiveTab(3);
                    setIsSended(false);
                }
            }
        });
    };

    const setinformerKey = async () => {
        await setLocalStorage('informer', key);
    };

    const checkAnswer = async () => {
        let surveyStorage = await ApiSurvey.getAnswerDraft({ id: survey_id });
        if (surveyStorage.status == 200) {
            try {
                const { data } = surveyStorage;
                surveyStorage = data;
                setsurveyStorage(surveyStorage);
                if (encodeBase64(surveyStorage.survey_id) == survey_id) {
                    if (surveyStorage.answer.length > 0) {
                        setIsContinue(true);
                    }
                }
            } catch (error) { }
        }
    };

    const checkAnswerSended = async () => {
        let surveyStorage = await ApiSurvey.getAnswerHistory({ id: survey_id });
        if (surveyStorage.status == 200) {
            try {
                const { data } = surveyStorage;
                console.log(data);
                surveyStorage = data;
                if (encodeBase64(surveyStorage.survey_id) == survey_id) {
                    setIsSended(true);
                }
            } catch (error) { }
        }
    };

    const viewSurvey = async () => {
        try {
            await ApiSurvey.viewSurvey({ id: survey_id });
        } catch (error) { }
    };

    const checkButtonSubmit = () => {
        if (isSended) {
            return (
                <div className='btn-brown'>
                    <ReloadOutlined />
                    <span>ทำใหม่</span>
                </div>
            );
        }
        if (isContinue) {
            return (
                <div className='summary'>
                    <ReloadOutlined />
                    <span>ทำแบบสอบถามต่อ</span>
                </div>
            );
        } else {
            return (
                <div>
                    <FormOutlined />
                    <span>ส่งความคิดเห็น</span>
                </div>
            );
        }
    };

    const checkAccessRightButton = () => {
        let text = ``;
        if (survey.access_right == 3) {
            if (checkUserBool(userState)) {
                return false;
            } else {
                text = (<div
                    onClick={() => {
                        // history.push('/Login')
                        setactiveTab(3)
                    }}
                    className='bottom-button'
                >
                    <div>
                        <span>เข้าสู่ระบบก่อนแสดงความคิดเห็น</span>
                    </div>
                </div>
                );
            }
        }
        else if (survey.access_right == 2) {
            if (checkUserBool(userState)) {
                if (userState.is_verify) {
                    return false;
                } else {
                    text = (<div
                        onClick={() => {
                            // history.push('/UserProfile?usertab=2')
                            setactiveTab(3)
                        }}
                        className='bottom-button'
                    >

                        <div>
                            <span>ยืนยันตัวตนก่อนแสดงความคิดเห็น</span>
                        </div>
                    </div>
                    );
                }

            } else {
                text = (<div
                    onClick={() => {
                        history.push('/Login')
                    }}
                    className='bottom-button'
                >
                    <div>
                        <span>เข้าสู่ระบบก่อนแสดงความคิดเห็น</span>
                    </div>
                </div>
                );
            }
        }
        else if (survey.access_right == 4) {
            if (checkUserBool(userState)) {
                if (userState.is_verify) {
                    return false;
                } else {
                    text = (<div
                        onClick={() => {
                            // history.push('/UserProfile?usertab=2')
                            setactiveTab(3)
                        }}
                        className='bottom-button'
                    >
                        <div>
                            <span>ยืนยันตัวตนก่อนแสดงความคิดเห็น</span>
                        </div>
                    </div>
                    );
                }

            } else {
                text = (<div
                    onClick={() => {
                        // history.push('/UserProfile?usertab=2')
                        setactiveTab(3)
                    }}
                    className='bottom-button'
                >
                    <div>
                        <span>เข้าสู่ระบบก่อนแสดงความคิดเห็น</span>
                    </div>
                </div>
                );
            }
        }
        else {
            return false;
        }
        return text;
    }

    const handleFavorite = async (e, item) => {
        e.stopPropagation();
        if (checkUser(userState)) {
            const model = {
                project_id: item.project_id,
            };
            try {
                const result = await ApiSurvey.favoriteProject(model);
                if (result.status == 200) {
                    setsurvey({ ...survey, is_like: !survey.is_like });
                }
            } catch (error) {
                console.log(error);
                Swal.close();
            }
        }
    };

    const checkAccessRight = () => {
        if (survey.access_right == 3) {
            if (checkUserBool(userState)) {
                return false;
            } else {
                return true;
            }
        } else if (survey.access_right == 2) {
            if (checkUserBool(userState)) {
                if (userState.is_verify) {
                    return false;
                } else {
                    return true;
                }

            } else {
                return true;
            }
        }
        else if (survey.access_right == 4) {
            if (checkUserBool(userState)) {
                if (userState.is_verify) {
                    return false;
                } else {
                    return true;
                }

            } else {
                return true;
            }
        }
        else {
            return false;
        }

    }

    useEffect(() => {
        if (document.getElementById("tailwind-js")) {

        } else {
            const script = document.createElement("script");
            script.src = `${StaticImage("/tailwind.js")}`;
            script.id = "tailwind-js";
            script.async = true;
            document.body.appendChild(script);
        }
    }, []);

    return <div id='survey-detail-page'>
        <div className='section-banner'></div>
        <div
            className='d-flex-center flex-column w-100'
            style={{
                position: 'absolute',
                marginTop: -50,
                top: 0,
                height: 300,
                backgroundColor: '#0c4da2',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: -1,
            }}
        ></div>
        <div
            className='d-flex-center flex-column'
            style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Container>
                <div
                    className='d-flex menu-top'
                    style={{ justifyContent: 'space-between' }}
                >
                    <div className="d-flex" style={{ alignItems: 'center' }}>
                        <div
                            className='cursor-pointer back-button'
                            onClick={() => {
                                history.go(-1);
                            }}
                        >
                            <span>{'ย้อนกลับ'}</span>
                        </div>
                        <div className={`flex-1 project-name ${width > 768 ? "" : "project-name-2-line"}`} style={{

                        }}>
                            <span>{survey.project_name}</span>

                        </div>

                    </div>

                </div>
                <div
                    style={{ backgroundColor: 'white', padding: '5px 10px', width: 'fit-content', borderRadius: 10, marginTop: 10 }}
                    onClick={() => {
                        history.push(`/ProjectTimeline?projectId=${encodeBase64(survey.project_id)}`);
                    }}
                    className="d-flex-align-center cursor-pointer">
                    <img
                        src={StaticImage("/images/login/eye-duotone-2.png")}
                        style={{ marginRight: 10 }}
                        alt="calendar"
                        height={18}
                        width={26}
                    />
                    <span className="text-bold text-primary">ดูรายละเอียดโครงการ</span>
                </div>
                {
                    width >= 768 && <div className='rule-container survey-card-container'>
                        <SurveyCardItem handleFavorite={handleFavorite} survey={survey} law_group={law_group} setShowShare={setShowShare} />
                    </div>
                }
                {
                    width < 768 && <div className='rule-container survey-card-container-mobile'>
                        <SurveyCardItemMobile survey={survey} law_group={law_group} setShowShare={setShowShare} handleFavorite={handleFavorite} />
                    </div>
                }
                <div className='flex gap-x-4 items-center mt-4'>
                    <div className="d-flex menu-container flex-1 !mt-0" style={{ justifyContent: 'space-between' }}>

                        <div onClick={() => setactiveTab(1)} className={`d-flex ${activeTab == 1 ? "active" : ""}`}>
                            <span>ข้อมูลการรับฟังความเห็น</span>
                        </div>
                        {
                            survey.survey_type_id === 3 &&
                            <div onClick={() => setactiveTab(6)} className={`d-flex ${activeTab == 6 ? "active" : ""}`}>
                                <span>ข้อมูลกฎหมาย</span>
                            </div>
                        }
                        {
                            (survey.survey_type_id === 2 || survey.survey_type_id === 4 || survey.survey_type_id === 5) &&
                            <div onClick={() => setactiveTab(2)} className={`d-flex ${activeTab == 2 ? "active" : ""}`}>
                                <span>ร่างกฎหมาย</span>
                            </div>
                        }


                        <div onClick={() => setactiveTab(3)} className={`d-flex ${activeTab == 3 ? "active" : ""}`}>
                            <span>แบบสำรวจ</span>
                        </div>

                        <div onClick={() => setactiveTab(4)} className={`d-flex ${activeTab == 4 ? "active" : ""}`}>
                            <span>ข้อมูลติดต่อหน่วยงาน</span>
                        </div>
                        {
                            survey.is_webboard == 1 &&
                            <div onClick={() => setactiveTab(7)} className={`d-flex ${activeTab == 7 ? "active" : ""}`}>
                                <span>กระดานสนทนา</span>
                            </div>
                        }
                    </div>
                    <div className='hidden sm:flex'>
                        {(survey.isconclude == 'O' || !survey.isconclude) &&
                            survey.date_balance >= 0 && (
                                <>
                                    {!checkAccessRight() ?
                                        <div
                                            onClick={() => {
                                                isSended
                                                    ? deleteOldAnswer()
                                                    : setactiveTab(3)
                                            }}
                                            className=''
                                        >
                                            {
                                                isSended ? <button className='bg-[#e3d6bd] text-[#847049] rounded-lg px-2 py-3 flex items-center gap-x-2' >
                                                    <ReloadOutlined />
                                                    <span>ทำใหม่</span>
                                                </button> :
                                                    (isContinue) ? <button className='bg-[#387436] text-white rounded-lg px-2 py-3 flex items-center gap-x-2' >
                                                        <ReloadOutlined />
                                                        <span>ทำแบบสอบถามต่อ</span>
                                                    </button> :
                                                        <button className='bg-[#0c4da2] text-white rounded-lg px-2 py-3 flex items-center gap-x-2' >
                                                            <FormOutlined />
                                                            <span>ส่งความคิดเห็น</span>
                                                        </button>
                                            }
                                        </div>
                                        : <>
                                            {checkAccessRightButton()}
                                        </>
                                    }
                                </>
                            )}
                        {(survey.isconclude == 'O' || !survey.isconclude) &&
                            survey.date_balance < 0 && (
                                <button disabled className='disabled bg-gray-600 text-white rounded-lg px-2 py-3 flex items-center gap-x-2'>
                                    <Loading3QuartersOutlined />
                                    <span>อยู่ระหว่างสรุปผล</span>
                                </button>
                            )}
                        {survey.isconclude == 'D' && (
                            <div className=''>
                                <button disabled className='disabled bg-gray-600 text-white rounded-lg px-2 py-3 flex items-center gap-x-2'>
                                    <Loading3QuartersOutlined />
                                    <span>อยู่ระหว่างสรุปผล</span>
                                </button>
                            </div>
                        )}
                        {survey.isconclude == 'C' && (
                            <div className=''>
                                <button disabled className='disabled bg-gray-600 text-white rounded-lg px-2 py-3 flex items-center gap-x-2'>
                                    <CloseCircleOutlined />
                                    <span>ไม่มีการสรุปผล</span>
                                </button>
                            </div>
                        )}
                        {survey.isconclude == 'B' && (
                            <div className=''>
                                <button disabled className='disabled bg-gray-600 text-white rounded-lg px-2 py-3 flex items-center gap-x-2'>
                                    <CloseCircleOutlined />
                                    <span>ปิดก่อนกำหนด</span>
                                </button>
                            </div>
                        )}
                        {survey.isconclude == 'P' && (
                            <div className=''>
                                <button
                                    onClick={() => {
                                        history.push(`/Summary?survey_id=${encodeBase64(survey.survey_id)}`);
                                    }}
                                    className='bg-[#387436] text-white rounded-lg px-2 py-3 flex items-center gap-x-2' >
                                    <FileOutlined />
                                    <span>อ่านสรุปผลการรับฟัง</span>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                {activeTab == 1 && (survey.survey_type_id === 3 ? <Tab1Evaluate survey={survey} /> : <Tab1 survey={survey} />)}
                {activeTab == 2 && <Tab2 survey={survey} survey_id={survey_id} />}
                {activeTab == 4 && <Tab4 survey={survey} survey_id={survey_id} />}
                {activeTab == 3 && <Tab3 survey={survey} survey_id={survey_id} surveyStorage={surveyStorage}
                    isContinue={isContinue}
                    isSended={isSended}
                    deleteOldAnswer={deleteOldAnswer}
                />}
                {activeTab == 6 && <Tab6 survey={survey} />}
                {
                    (activeTab != 3 && activeTab != 7) &&
                    <>
                        {(survey.isconclude == 'O' || !survey.isconclude) &&
                            survey.date_balance >= 0 && (
                                <>
                                    {!checkAccessRight() ?
                                        <div
                                            onClick={() => {
                                                isSended
                                                    ? deleteOldAnswer()
                                                    : setactiveTab(3)
                                            }}
                                            className='bottom-button'
                                        >
                                            {checkButtonSubmit()}
                                        </div>
                                        : <>
                                            {checkAccessRightButton()}
                                        </>
                                    }
                                </>
                            )}
                        {(survey.isconclude == 'O' || !survey.isconclude) &&
                            survey.date_balance < 0 && (
                                <div className='bottom-button'>
                                    <div className='disabled'>
                                        <Loading3QuartersOutlined />
                                        <span>อยู่ระหว่างสรุปผล</span>
                                    </div>
                                </div>
                            )}
                        {survey.isconclude == 'D' && (
                            <div className='bottom-button'>
                                <div className='disabled'>
                                    <Loading3QuartersOutlined />
                                    <span>อยู่ระหว่างสรุปผล</span>
                                </div>
                            </div>
                        )}
                        {survey.isconclude == 'C' && (
                            <div className='bottom-button'>
                                <div className='disabled'>
                                    <CloseCircleOutlined />
                                    <span>ไม่มีการสรุปผล</span>
                                </div>
                            </div>
                        )}
                        {survey.isconclude == 'B' && (
                            <div className='bottom-button'>
                                <div className='disabled'>
                                    <CloseCircleOutlined />
                                    <span>ปิดก่อนกำหนด</span>
                                </div>
                            </div>
                        )}
                        {survey.isconclude == 'P' && (
                            <div
                                onClick={() => {
                                    history.push(`/Summary?survey_id=${encodeBase64(survey.survey_id)}`);
                                }}
                                className='bottom-button'
                            >
                                <div className='summary'>
                                    <FileOutlined />
                                    <span>อ่านสรุปผลการรับฟัง</span>
                                </div>
                            </div>
                        )}
                    </>
                }
                {activeTab == 7 && <TabWebboard
                    project_id={survey.project_id}
                    survey_id={survey_id}
                />
                }


            </Container>
        </div >

        {showShare && (
            <OutsideClickHandler
                onOutsideClick={() => {
                    setShowShare(false);
                }}
            > (
                <div className='share-container'>
                    <div className='header'>
                        <span>แชร์</span>
                        <img
                            onClick={() => setShowShare(false)}
                            style={{ position: 'absolute', right: 25 }}
                            src={StaticImage('/images/icon/drawer-header-close.png')}
                        />
                    </div>


                    <div className='share-button'>
                        <div>
                            <FacebookShareButton
                                url={share.shareUrl}
                                quote={share.titleFB}
                                className='Demo__some-network__share-button'
                            >
                                <FacebookIcon size={40} />
                            </FacebookShareButton>
                            <span>Facebook</span>
                        </div>
                        {/* <div>
              <LineShareButton url={share.shareUrl} title={share.subject}>
                <LineIcon size={40} />
              </LineShareButton>
              <span>LINE</span>
            </div> */}
                        <div>
                            <TwitterShareButton
                                url={share.shareUrl}
                                title={share.titleTW.substr(0, 170)}
                                className='Demo__some-network__share-button'
                            >
                                <TwitterIcon size={40} />
                            </TwitterShareButton>
                            <span>Twitter</span>
                        </div>
                        <div>
                            <EmailShareButton
                                url={''}
                                subject={share.subject.substr(0, 120)}
                                body={share.title}
                                className='Demo__some-network__share-button'
                            >
                                <EmailIcon size={40} />
                            </EmailShareButton>
                            <span>E-mail</span>
                        </div>

                        {/* <div
              onClick={() =>
                (window.location.href = 'https://line.me/ti/p/JMt9pd3ZxU')
              }
            >
              <LineShareButton
                url={share.shareUrl}
                title={share.title.substr(0, 170)}
                className='Demo__some-network__share-button'
              >
                <LineIcon size={40} />
              </LineShareButton>
              <span>LINE</span>
            </div> */}
                        <div>
                            <iframe
                                frameborder='0'
                                data-size='large'
                                src={
                                    'https://timeline.line.me/social-plugin-widget/share?buttonType=share-d&amp;type=share&amp;lang=en&amp;id=1&amp;size=large&amp;origin=' +
                                    share.shareUrl +
                                    '&amp;title=' +
                                    share.title.substr(0, 170) +
                                    ''
                                }
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    // visibility: 'visible',
                                    // position: 'static',
                                    // alignItems: 'center',
                                    // marginLeft: '40px',
                                }}
                            ></iframe>
                            <span style={{ marginTop: 5 }}>LINE</span>
                        </div>
                        <div
                            onClick={() =>
                                clipboard.copy(
                                    `${window.location.origin}/listeningDetail?survey_id=${survey_id}`
                                )
                            }
                        >
                            <div className='circle'>
                                <img
                                    src={StaticImage(
                                        '/images/icon/listeningDetail/link-regular.png'
                                    )}
                                />
                            </div>
                            <span>
                                {clipboard.copied ? 'คัดลอกลิงค์สำเร็จ' : 'คัดลอกลิงค์'}
                            </span>
                        </div>
                        {/* <div
              onClick={() =>
                (window.location.href = 'https://line.me/ti/p/JMt9pd3ZxU')
              }
            >
              <div>
                <img
                  src={StaticImage('/images/buttons_en_v3/square-default.png')}
                  style={{ width: '40px', height: '40px' }}
                />
              </div>
              <span>LINE</span>
            </div> */}
                    </div>
                </div>
            </OutsideClickHandler>
        )}
    </div >
}



export default SurveyDetail
